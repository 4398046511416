<template>
	<div class="container p-4 mb-3 bg-white rounded-3 overflow-hidden bg-white shadow border">
		<p class="h5 fw-bold text-center">Смета</p>

		<CalcInfoChart :calcInfo="calcInfo" />

		<table class="table table-sm table-borderless table-hover calc-table m-0 small">
			<thead class="table-primary">
				<tr>
					<th colspan="3" class="p-2 h5">Материалы ({{ numberToRUB(calcInfo.materialsPrice) }})</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>{{ calcInfo.metalTitle }}</td>
					<td>{{ numberToWeight(calcInfo.metalWeight) }}</td>
					<td>{{ numberToRUB(calcInfo.metalPrice) }}</td>
				</tr>
			</tbody>

			<thead v-if="calcInfo.diamondStones" class="bg-light">
				<tr>
					<th colspan="3" class="h6 fw-bold">
						Бриллианты ({{ calcInfo.diamondStones.pcs }} шт / {{ calcInfo.diamondStones.weight.toFixed(3) }} ct. /
						{{ numberToRUB(calcInfo.diamondStones.price) }})
					</th>
				</tr>
			</thead>
			<tbody v-if="calcInfo.diamondStones">
				<tr v-for="(diamonds, index) in calcInfo.diamondStones.stones" :key="index" :diamonds="diamonds">
					<td>
						{{ diamonds.cut }} {{ diamonds.size }}
						<span class="text-muted"> / {{ diamonds.weight }}</span>
					</td>
					<td>{{ diamonds.pcs }}</td>
					<td>{{ numberToRUB(diamonds.price * diamonds.pcs) }}</td>
				</tr>
			</tbody>

			<!-- <thead v-if="calcInfo.otherStones" class="bg-light">
				<tr>
					<th colspan="3" class="h6 fw-bold">Другие камни</th>
				</tr>
			</thead>
			<tbody v-if="calcInfo.otherStones">
				<tr v-for="(otherStones, index) in calcInfo.otherStones.stones" :key="index" :otherStones="otherStones">
					<td>{{ otherStones.name }} {{ otherStones.cut }}</td>
					<td>{{ otherStones.pcs }}</td>
					<td :class="{ 'text-danger': !otherStones.calc }">
						{{ numberToRUB(otherStones.price) }}
					</td>
				</tr>
			</tbody> -->

			<template v-for="(stones, index) in otherStones" :key="index">
				<thead class="bg-light">
					<tr>
						<th colspan="3" class="h6 fw-bold">
							{{ stones.title }} ({{ stones.pcs }} шт / {{ stones.weight.toFixed(3) }} ct. / {{ numberToRUB(stones.price) }})
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(stone, index) in stones.elements" :key="index">
						<td>
							{{ firstUpper(stone.cut) }} {{ firstUpper(stone.size) }} <span class="text-muted"> / {{ +stone.weight }}</span>
						</td>
						<td>{{ stone.pcs }}</td>
						<td :class="{ 'text-danger': !stone.calc }">
							{{ numberToRUB(stone.price * stone.pcs) }}
						</td>
					</tr>
				</tbody>
			</template>

			<thead class="table-primary">
				<tr>
					<th colspan="3" class="p-2 h5">Дополнительные расходы ({{ numberToRUB(calcInfo.additionalExpenses.price) }})</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>3d печать</td>
					<td>{{ numberToVolume(calcInfo.volume) }}</td>
					<td>{{ numberToRUB(calcInfo.waxPrice) }}</td>
				</tr>

				<tr>
					<td>Литьё</td>
					<td>{{ numberToWeight(calcInfo.metalWeight) }}</td>
					<td>{{ numberToRUB(calcInfo.castingPrice) }}</td>
				</tr>
			</tbody>

			<tbody v-if="calcInfo.additionalExpenses">
				<tr v-for="(additionalExpense, index) in calcInfo.additionalExpenses.items" :key="index" :additionalExpense="additionalExpense">
					<td>{{ additionalExpense.title }}</td>
					<td>{{ numberToDays(additionalExpense.days) }}</td>
					<td>{{ numberToRUB(additionalExpense.price) }}</td>
				</tr>
			</tbody>

			<thead v-if="calcInfo.works" class="table-warning">
				<tr>
					<th colspan="3" class="p-2 h5">Работа ({{ numberToRUB(calcInfo.works.price) }})</th>
				</tr>
			</thead>

			<tbody v-if="calcInfo.works">
				<tr v-for="(work, index) in calcInfo.works.items" :key="index" :work="work">
					<td>{{ work.title }}</td>
					<td>{{ numberToDays(work.days) }}</td>
					<td>{{ numberToRUB(work.price) }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import CalcInfoChart from '@/components/single-jewelry/calc-info-chart.component.vue'
import { defineComponent } from 'vue'
import { numberToRUB, numberToDays, numberToVolume, numberToWeight, firstUpper } from '@/scripts/filters'

export default defineComponent({
	name: 'JewelryItemCalcInfo',
	components: {
		CalcInfoChart,
	},

	props: ['calcInfo'],

	methods: {
		numberToRUB(value) {
			return numberToRUB(value)
		},
		numberToDays(value) {
			return numberToDays(value)
		},
		numberToVolume(value) {
			return numberToVolume(value)
		},
		numberToWeight(value) {
			return numberToWeight(value)
		},
		firstUpper(value) {
			return firstUpper(value)
		},
	},

	computed: {
		otherStones() {
			if (this.calcInfo.otherStones) {
				const otherStones = []
				let otherStonesTitles = []

				this.calcInfo.otherStones.stones.forEach((element) => {
					otherStonesTitles.push(firstUpper(element.name))
				})

				otherStonesTitles = [...new Set(otherStonesTitles)]

				otherStonesTitles.forEach((title) => {
					const stone = {}
					stone.title = title
					stone.pcs = 0
					stone.price = 0
					stone.weight = 0
					stone.elements = []

					this.calcInfo.otherStones.stones.forEach((element) => {
						if (title === firstUpper(element.name)) {
							stone.pcs += +element.pcs
							if (element.calc) stone.price += element.price * element.pcs
							stone.weight += +element.pcs * element.weight
							stone.elements.push(element)
						}
					})

					otherStones.push(stone)
				})
				return otherStones
			}
			return null
		},
	},
})
</script>

<style scoped>
.calc-table tr td:nth-child(2) {
	width: 20%;
	text-align: right;
}
.calc-table tr td:nth-child(3) {
	width: 20%;
	text-align: right;
	font-weight: bold;
}
</style>
