<template>
	<div class="modal fade" id="image-library" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-scrollable">
			<div class="modal-content">
				<!-- Modal header -->
				<div class="modal-header justify-content-center">
					<ul class="nav nav-pills" role="tablist">
						<li class="nav-item" role="presentation">
							<a
								class="nav-link active"
								data-bs-toggle="pill"
								href="#image-library-add"
								role="tab"
								aria-controls="image-library-add"
								:aria-selected="true"
								><i class="fas fa-plus-circle"></i> Создать</a
							>
						</li>
						<li class="nav-item" role="presentation">
							<a
								class="nav-link"
								data-bs-toggle="pill"
								href="#image-library-select"
								id="image-library-tab"
								role="tab"
								aria-controls="image-library-select"
								aria-selected="false"
								><i class="fas fa-copy"></i> Галерея</a
							>
						</li>
					</ul>
				</div>

				<!-- Modal body -->
				<div class="modal-body bg-light">
					<div class="tab-content">
						<div class="tab-pane fade show active" id="image-library-add" role="tabpanel" aria-labelledby="pills-home-tab">
							<div class="row justify-content-center">
								<div class="col col-lg-7">
									<div class="row ">
										<!-- Select image file -->
										<div class="col">
											<div class="input-group">
												<input @change="loadImage($event)" accept="image/*" class="form-control" type="file" />
												<!-- <button @click="pasteImageButton($event)" type="button" class="btn btn-secondary">
													<i class="fas fa-copy"></i>
												</button> -->
											</div>
										</div>
									</div>

									<!-- Image -->
									<div v-show="!!imageToUpload" class="row justify-content-center">
										<div class="col my-3">
											<!-- Not cropping image -->
											<img v-show="!cropping && !!imageToUpload" :src="imageToUpload" class="img-fluid shadow" />
											<div v-show="cropping && !!imageToUpload" class="shadow">
												<img :src="imageToUpload" id="image-to-crop" class="img-fluid img-thumbnail" style="display: block; max-width: 100%;" />
											</div>
										</div>
									</div>

									<!-- Control -->
									<div v-show="!!imageToUpload" class="row justify-content-center">
										<div class="col">
											<!-- Crop -->
											<div class="form-check form-check-inline form-switch">
												<input v-model="cropping" class="form-check-input" type="checkbox" />
												<label class="form-check-label">Кадрировать</label>
											</div>

											<!-- 1:1 Aspect -->
											<div v-show="cropping" class="form-check form-check-inline form-switch">
												<input v-model="squareAspect" class="form-check-input" type="checkbox" />
												<label class="form-check-label">1:1</label>
											</div>

											<!-- Add to library botton -->
											<button @click="uploadImage()" type="button" class="btn btn-outline-primary">
												<i class="fas fa-file-download"></i> В галерею
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Select image from gallery -->
						<div class="tab-pane fade" id="image-library-select" role="tabpanel" aria-labelledby="pills-profile-tab">
							<div class="row">
								<div v-for="image in allImages" :key="image.id" class="col-4 col-md-3 col-lg-2 p-1">
									<div @click="selectImage(image.id)" class="square-ratio" :class="{ 'selected-image': selectedImage == image.id }">
										<img :data-src="image.thumbnail" class="img-thumbnail lazy-image" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Modal footer -->
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
					<button @click="addImage()" type="button" class="btn btn-primary" data-bs-dismiss="modal">Выбрать</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { Cropper } from 'vue-advanced-cropper'
import { defineComponent, nextTick } from 'vue'
import { Media } from '@/store'
import Cropper from 'cropperjs'
// let cropper
import { imageLazyLoad } from '@/scripts/imageLazyLoad.ts'

export default defineComponent({
	name: 'ImageLibrary',
	components: {
		// Cropper,
	},
	data() {
		return {
			activeTab: 0,
			selectedImage: null,
			imageSourceFile: null,
			imageToUpload: null,
			cropping: true,
			squareAspect: true,
			cropper: null,
		}
	},

	mounted() {
		this.selectedImage = null
		Media.updateImages()
		window.addEventListener('paste', this.pasteImage)
	},

	unmounted() {
		window.removeEventListener('paste', this.pasteImage)
	},

	computed: {
		allImages() {
			return Media.images
		},
	},
	watch: {
		allImages() {
			nextTick(() => {
				imageLazyLoad()
			})
		},

		squareAspect() {
			this.cropper.setAspectRatio(this.squareAspect ? 1 : 0)
		},
	},

	methods: {
		selectImage(id) {
			if (this.selectedImage != id) {
				this.selectedImage = id
			} else {
				this.selectedImage = null
			}
		},
		addImage() {
			if (this.selectedImage) {
				this.$emit('addImage', this.selectedImage)
				this.selectedImage = null
				if (this.cropper) this.cropper.destroy()
			}
		},
		loadImage(event) {
			const input = event.target
			if (input.files && input.files[0]) {
				const reader = new FileReader()
				reader.onload = (event) => {
					this.cropperInit(event)
				}
				reader.readAsDataURL(input.files[0])
				this.imageSourceFile = input.files[0]
			}
		},

		pasteImage(event) {
			const blob = event.clipboardData.items[0].getAsFile()
			// console.log(blob)
			this.imageSourceFile = new File([blob], Date.now() + '-' + blob.name, { type: blob.type, lastModified: blob.lastModified })
			const reader = new FileReader()
			reader.onload = (event) => {
				this.cropperInit(event)
			}
			reader.readAsDataURL(blob)
		},

		cropperInit(event) {
			this.imageToUpload = event.target.result
			nextTick(() => {
				const image = document.getElementById('image-to-crop')
				if (this.cropper) this.cropper.destroy()
				this.cropper = new Cropper(image, {
					zoomable: false,
					aspectRatio: this.squareAspect ? 1 : 0,
				})
			})
		},

		uploadImage() {
			if (this.cropping) {
				this.cropper.getCroppedCanvas({ fillColor: '#ffffff' }).toBlob((blob) => {
					// console.log(this.imageSourceFile)
					Media.uploadImage({ data: blob, filename: this.imageSourceFile.name })
					this.resetImage()
				}, 'image/jpeg')
			} else if (!this.cropping && !!this.imageSourceFile) {
				Media.uploadImage({ data: this.imageSourceFile, filename: this.imageSourceFile.name })
				this.resetImage()
			}
		},

		resetImage() {
			this.cropper.destroy()
			this.imageSourceFile = this.imageToUpload = null

			const libraryTabEl = document.querySelector('#image-library-tab')
			const libraryTab = new this.$bootstrap.Tab(libraryTabEl)
			libraryTab.show()
		},
	},
})
</script>
<style lang="scss" scoped>
:deep(.cropper-dashed) {
	border-color: black;
	--padding-size: 15%;
	--stencil-size: 70%;
}

:deep(.dashed-h) {
	top: var(--padding-size);
	height: var(--stencil-size);
}

:deep(.dashed-v) {
	left: var(--padding-size);
	width: var(--stencil-size);
}

:deep(.cropper-modal) {
	opacity: 0.3;
}
:deep(.cropper-face) {
	background-color: transparent;
}

.selected-image {
	.img-thumbnail {
		background-color: var(--bs-primary) !important;
	}
}
</style>
