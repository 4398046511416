<template>
	<div class="container-fluid mb-2">
		<!-- МАТЕРИАЛЫ -->
		<div class="row">
			<div class="col-3 p-0">
				<div class="h5 m-0">{{ numberToRUB(calcInfo.materialsPrice) }}</div>
				<div class="small text-secondary">МАТЕРИАЛЫ</div>
			</div>
			<div class="col">
				<div class="progress">
					<div class="progress-bar bg-gradient" :style="`width: ${materials}%`"></div>
					<div
						v-if="calcInfo.otherStones"
						class="progress-bar bg-danger bg-gradient"
						:style="`width: ${excludedMaterials}%; max-width: ${100 - materials}%;`"
					></div>
					<div class="ps-1 d-flex align-items-center" style="width: 0">
						{{ numberToRUB(calcInfo.materialsPrice) }}
					</div>
				</div>
			</div>
		</div>

		<!-- ДОП РАСХОДЫ -->
		<div class="row mt-2">
			<div class="col-3 p-0">
				<div class="h5 m-0">{{ numberToRUB(calcInfo.additionalExpenses.price) }}</div>
				<div class="small text-secondary">РАСХОДЫ</div>
			</div>
			<div class="col">
				<div class="progress">
					<div class="progress-bar bg-transparent" :style="`width: ${materials}%`"></div>
					<div class="progress-bar bg-gradient" :style="`width: ${additionalExpenses}%`"></div>
					<div class="ps-1 d-flex align-items-center" style="width: 0">
						{{ numberToRUB(calcInfo.materialsPrice + calcInfo.additionalExpenses.price) }}
					</div>
				</div>
			</div>
		</div>

		<!-- РАБОТА -->
		<div class="row mt-2">
			<div class="col-3 p-0">
				<div class="h5 m-0">{{ numberToRUB(calcInfo.works.price) }}</div>
				<div class="small text-secondary">РАБОТА</div>
			</div>
			<div class="col">
				<div class="progress">
					<div class="progress-bar bg-transparent" :style="`width: ${materials + additionalExpenses}%`"></div>
					<div class="progress-bar bg-warning bg-gradient" :style="`width: ${works}%`"></div>
					<div class="ps-1 d-flex align-items-center" style="width: 0">
						{{ numberToRUB(calcInfo.materialsPrice + calcInfo.additionalExpenses.price + calcInfo.works.price) }}
					</div>
				</div>
			</div>
		</div>

		<!-- ОПЗ -->
		<div class="row mt-2">
			<div class="col-3 p-0">
				<div class="h5 m-0">{{ numberToRUB(calcInfo.opzPrice) }}</div>
				<div class="small text-secondary">ОПЗ ({{ numberToDays(calcInfo.works.days) }})</div>
			</div>
			<div class="col">
				<div class="progress">
					<div class="progress-bar bg-transparent" :style="`width: ${materials + additionalExpenses + works}%`"></div>
					<div class="progress-bar bg-info bg-gradient" :style="`width: ${opz}%`"></div>
					<div class="ps-1 d-flex align-items-center" style="width: 0">
						{{ numberToRUB(calcInfo.materialsPrice + calcInfo.additionalExpenses.price + calcInfo.works.price + calcInfo.opzPrice) }}
					</div>
				</div>
			</div>
		</div>

		<!-- НАЦЕНКА -->
		<div class="row mt-2">
			<div class="col-3 p-0">
				<div class="h5 m-0">{{ numberToRUB(calcInfo.extraPrice) }}</div>
				<div class="small text-secondary">НАЦЕНКА +{{ calcInfo.extraPercent }}%</div>
			</div>
			<div class="col">
				<div class="progress">
					<div class="progress-bar bg-transparent" :style="`width: ${materials + additionalExpenses + works + opz}%`"></div>
					<div class="progress-bar bg-success bg-gradient" :style="`width: ${extraPrice}%`"></div>
				</div>
			</div>
		</div>

		<div class="text-end">
			<!-- <h4 class="font-weight-bold">Материалы: {{ numberToRUB(calcInfo.materialsPrice + calcInfo.additionalExpenses.price) }}</h4> -->
			<h4 class="text-success font-weight-bold">Итого: {{ numberToRUB(calcInfo.finalPrice) }}</h4>
			<h6>Нал: {{ numberToRUB(calcInfo.extraPrice) }}</h6>
			<h6 class="text-danger">Безнал: {{ numberToRUB(calcInfo.cashlessTaxPrice) }}</h6>
			<h6 class="text-danger">Касса: {{ numberToRUB(calcInfo.cashboxTaxPrice) }}</h6>
		</div>
	</div>
</template>
+
<script>
import { defineComponent } from 'vue'
import { numberToRUB, numberToDays, numberToVolume, numberToWeight } from '@/scripts/filters'

export default defineComponent({
	name: 'CalcInfoChart',
	props: ['calcInfo'],
	data() {
		return {
			barHeight: '1.5rem',
		}
	},
	computed: {
		materials() {
			const materials = (this.calcInfo.materialsPrice / this.calcInfo.finalPrice) * 100
			return materials
		},
		excludedMaterials() {
			const excludedMaterials = ((this.calcInfo.otherStones.excludedPrice ?? 0) / this.calcInfo.finalPrice) * 100
			return excludedMaterials
		},
		additionalExpenses() {
			const additionalExpenses = (this.calcInfo.additionalExpenses.price / this.calcInfo.finalPrice) * 100
			return additionalExpenses
		},
		works() {
			const works = (this.calcInfo.works.price / this.calcInfo.finalPrice) * 100
			return works
		},
		opz() {
			const opz = (this.calcInfo.opzPrice / this.calcInfo.finalPrice) * 100
			return opz
		},
		extraPrice() {
			const extraPrice = (this.calcInfo.extraPrice / this.calcInfo.finalPrice) * 100
			return extraPrice
		},
	},

	methods: {
		numberToRUB(value) {
			return numberToRUB(value)
		},
		numberToDays(value) {
			return numberToDays(value)
		},
		numberToVolume(value) {
			return numberToVolume(value)
		},
		numberToWeight(value) {
			return numberToWeight(value)
		},
	},
})
</script>
<style scoped>
.progress {
	height: 1.4rem !important;
}
</style>
