<template>
	<!-- Основная информация -->
	<div class="container p-4 bg-white rounded-3 shadow border">
		<!-- Title & save button -->
		<!-- Title -->
		<div class="h5 fw-bold text-center mb-3">
			Редактировать
			<!-- Save buttons -->
			<i v-show="!postChanged" class="far fa-save fs-5 text-muted"></i>
			<i v-show="postChanged && serverBusy" class="fas fa-save fs-5 text-muted"></i>
			<i v-show="postChanged && !serverBusy" @click="savePost" class="fas fa-save fs-5 text-success clickable-icon"></i>
		</div>

		<ul class="nav nav-pills justify-content-center mb-3" role="tablist">
			<li class="nav-item" role="presentation">
				<a
					class="nav-link"
					id="basic-info-tab"
					@click="changeTab"
					data-bs-toggle="pill"
					href="#pills-basic-info"
					role="tab"
					aria-controls="pills-home"
					aria-selected="true"
					><i class="fas fa-info-circle"></i> Инфо</a
				>
			</li>
			<li class="nav-item" role="presentation">
				<a
					class="nav-link"
					id="tech-info-tab"
					@click="changeTab"
					data-bs-toggle="pill"
					href="#pills-tech-info"
					role="tab"
					aria-controls="pills-profile"
					aria-selected="false"
					><i class="fas fa-cog"></i> Тех инфо</a
				>
			</li>
			<li class="nav-item" role="presentation">
				<a
					class="nav-link"
					id="calc-info-tab"
					@click="changeTab"
					data-bs-toggle="pill"
					href="#pills-calc-info"
					role="tab"
					aria-controls="pills-contact"
					aria-selected="false"
					><i class="fas fa-calculator"></i> Смета</a
				>
			</li>
		</ul>
		<div class="tab-content">
			<!-- Basic info -->
			<div class="tab-pane fade" id="pills-basic-info">
				<!-- Title -->
				<div class="row">
					<div class="col">
						<input v-model="post.title" placeholder="Название..." class="form-control" />
					</div>
				</div>
				<!-- Description -->
				<div class="row mt-2">
					<div class="col">
						<textarea v-model="post.acf.description" :placeholder="fieldsGroup.description.placeholder" class="form-control" rows="5" />
						<div class="form-text">{{ fieldsGroup.description.label }}</div>
					</div>
				</div>

				<hr />
				<p class="text-center fw-bold">Классификация</p>

				<div class="row g-2">
					<!-- Status -->
					<div class="col-auto">
						<select v-model="post.status" class="form-select form-select-sm" aria-label="Default select example">
							<option value="publish">Опубликовано</option>
							<option value="draft">Черновик</option>
						</select>
						<div class="form-text">Статус</div>
					</div>

					<!-- Tags -->
					<div class="col-auto">
						<select v-model="post.tags" class="form-select form-select-sm">
							<option :value="[]" selected>Нет</option>
							<option v-for="tag in tags" :value="[tag.id]" :key="tag.id">
								{{ tag.name }}
							</option>
						</select>
						<div class="form-text">Коллекция</div>
					</div>

					<!-- Categories -->
					<div class="col-auto">
						<div class="dropdown" @click.stop>
							<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								Категории
							</button>
							<div class="dropdown-menu p-2">
								<div class="form-check" v-for="category in categories" :key="category.id">
									<input v-model="post.categories" :value="category.id" type="checkbox" class="form-check-input" :id="category.id + 'checkbox'" />
									<label class="form-check-label" :for="category.id + 'checkbox'">
										{{ category.name }}
									</label>
								</div>
							</div>
						</div>
						<div class="form-text">Категории</div>
					</div>
				</div>

				<hr />

				<div class="row g-2">
					<div class="col">
						<!-- State -->
						<label class="form-label">{{ fieldsGroup.postState.label }}</label>
						<select v-model="post.acf.postState" class="form-select">
							<option v-for="(value, key) in fieldsGroup.postState.choices" :value="key" :key="key">
								{{ value }}
							</option>
						</select>
						<!-- Order -->
						<div class="form-check form-check-inline form-switch mt-1">
							<input v-model="post.acf.isPrivateOrder" class="form-check-input" type="checkbox" id="private-order-switch" />
							<label class="form-check-label" for="private-order-switch">{{ fieldsGroup.isPrivateOrder.label }}</label>
						</div>
					</div>
					<div class="col">
						<!-- Price -->
						<label class="form-label">{{ fieldsGroup.price.label }}</label>
						<div class="input-group">
							<input
								v-model="post.acf.price"
								:type="fieldsGroup.price.type"
								:min="fieldsGroup.price.min"
								:max="fieldsGroup.price.max"
								class="form-control"
							/>
							<span class="input-group-text" id="basic-addon2">{{ fieldsGroup.price.append }}</span>
						</div>
						<!-- Calc -->
						<div class="form-check form-check-inline form-switch mt-1">
							<input v-model="post.acf.isCalc" class="form-check-input" type="checkbox" id="is-calc-switch" />
							<label class="form-check-label" for="is-calc-switch">{{ fieldsGroup.isCalc.label }}</label>
						</div>
					</div>
				</div>

				<!-- Article -->
				<hr />
				<p class="text-center fw-bold">Артикул</p>

				<div class="row g-2">
					<!-- Article type -->
					<div class="col">
						<select v-model="post.acf.articleType" class="form-select form-select-sm">
							<option v-for="(value, key) in fieldsGroup.articleType.choices" :value="key" :key="key">
								{{ value }}
							</option>
						</select>
						<div class="form-text">{{ fieldsGroup.articleType.label }}</div>
					</div>

					<!-- Article number -->
					<div class="col">
						<input
							v-model="post.acf.articleNumber"
							:placeholder="fieldsGroup.articleNumber.placeholder"
							:type="fieldsGroup.articleNumber.type"
							:min="fieldsGroup.articleNumber.min"
							:max="fieldsGroup.articleNumber.max"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.articleNumber.label + ' (от ' + lastArtickleNumber + ')' }}</div>
					</div>

					<!-- Article category -->
					<div class="col">
						<select v-model="post.acf.articleCompl" class="form-select form-select-sm">
							<option v-for="(value, key) in fieldsGroup.articleCompl.choices" :value="key" :key="key">
								{{ value }}
							</option>
						</select>
						<div class="form-text">{{ fieldsGroup.articleCompl.label }}</div>
					</div>
				</div>

				<div class="row mt-2 g-2">
					<!-- Article metal -->
					<div class="col">
						<select v-model="post.acf.articleMetal" class="form-select form-select-sm">
							<option v-for="(value, key) in fieldsGroup.articleMetal.choices" :value="key" :key="key">
								{{ value }}
							</option>
						</select>
						<div class="form-text">{{ fieldsGroup.articleMetal.label }}</div>
					</div>

					<!-- Article mod -->
					<div class="col">
						<input
							v-model="post.acf.articleMod"
							:placeholder="fieldsGroup.articleMod.placeholder"
							:type="fieldsGroup.articleMod.type"
							:min="fieldsGroup.articleMod.min"
							:max="fieldsGroup.articleMod.max"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.articleMod.label }}</div>
					</div>

					<!-- Article group by -->
					<div class="col">
						<div class="form-check form-check-inline form-switch">
							<input v-model="post.acf.articleGroup" class="form-check-input" type="checkbox" id="article-group-switch" />
							<label class="form-check-label" for="article-group-switch">Группы</label>
						</div>
					</div>
				</div>

				<!-- Images -->
				<hr />
				<p class="text-center fw-bold">Изображения</p>
				<div class="row">
					<!-- Photos -->
					<div class="col-3 text-center px-1">
						Фото<br />
						<i
							@click="showImagesLibraryModal(fieldsGroup.photo.name)"
							data-bs-toggle="modal"
							data-bs-target="#image-library"
							class="fas fa-plus-circle fs-5 text-primary clickable-icon"
						/>

						<draggable v-if="post.acf.photo" v-model="post.acf.photo" item-key="element" class="list-group" ghost-class="ghost">
							<template #item="{ element }">
								<EditableImage @deleteImage="deleteImage($event)" :id="element" :url="getUrlById(element)" :imageType="fieldsGroup.photo.name" />
							</template>
						</draggable>
					</div>

					<!-- Renders -->
					<div class="col-3 text-center px-1">
						Рендеры<br />
						<i
							@click="showImagesLibraryModal(fieldsGroup.render.name)"
							data-bs-toggle="modal"
							data-bs-target="#image-library"
							class="fas fa-plus-circle fs-5 text-primary clickable-icon"
						/>

						<draggable v-if="post.acf.render" v-model="post.acf.render" item-key="element" class="list-group" ghost-class="ghost">
							<template #item="{ element }">
								<EditableImage @deleteImage="deleteImage($event)" :id="element" :url="getUrlById(element)" :imageType="fieldsGroup.render.name" />
							</template>
						</draggable>
					</div>

					<!-- Screenshots -->
					<div class="col-3 text-center px-1">
						Скриншоты<br />
						<i
							@click="showImagesLibraryModal(fieldsGroup.screenshot.name)"
							data-bs-toggle="modal"
							data-bs-target="#image-library"
							class="fas fa-plus-circle fs-5 text-primary clickable-icon"
						/>

						<draggable v-if="post.acf.screenshot" v-model="post.acf.screenshot" item-key="element" class="list-group" ghost-class="ghost">
							<template #item="{ element }">
								<EditableImage @deleteImage="deleteImage($event)" :id="element" :url="getUrlById(element)" :imageType="fieldsGroup.screenshot.name" />
							</template>
						</draggable>
					</div>

					<!-- Sketches -->
					<div class="col-3 text-center px-1">
						Эскизы<br />
						<i
							@click="showImagesLibraryModal(fieldsGroup.sketch.name)"
							data-bs-toggle="modal"
							data-bs-target="#image-library"
							class="fas fa-plus-circle fs-5 text-primary clickable-icon"
						/>

						<draggable v-if="post.acf.sketch" v-model="post.acf.sketch" item-key="element" class="list-group" ghost-class="ghost">
							<template #item="{ element }">
								<EditableImage @deleteImage="deleteImage($event)" :id="element" :url="getUrlById(element)" :imageType="fieldsGroup.sketch.name" />
							</template>
						</draggable>
					</div>
				</div>
				<ImagesLibrary @addImage="addImage($event)" />

				<!-- F360 model link -->
				<hr />
				<p class="text-center fw-bold">Ссылка на модель</p>
				<div class="row">
					<div class="col">
						<textarea v-model="post.acf.embeddedF360" class="form-control form-control-sm" rows="3" />
					</div>
				</div>
			</div>
			<!-- Tech info -->
			<div class="tab-pane fade" id="pills-tech-info">
				<div class="row g-2">
					<!-- Volume -->
					<div class="col">
						<input
							v-model="post.acf.volume"
							:type="fieldsGroup.volume.type"
							:placeholder="techInfo.volume ? techInfo.volume.toString() : fieldsGroup.volume.placeholder"
							:min="fieldsGroup.volume.min"
							:max="fieldsGroup.volume.max"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.volume.label }}</div>
					</div>

					<!-- Weight 925 -->
					<div class="col">
						<input
							v-model="post.acf.weight925"
							:type="fieldsGroup.weight925.type"
							:placeholder="techInfo.weight925 ? techInfo.weight925.toString() : fieldsGroup.weight925.placeholder"
							:min="fieldsGroup.weight925.min"
							:max="fieldsGroup.weight925.max"
							:class="{ 'border border-primary': post.acf.articleMetal == 925 }"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.weight925.label }}</div>
					</div>

					<!-- Weight 585 -->
					<div class="col">
						<input
							v-model="post.acf.weight585"
							:type="fieldsGroup.weight585.type"
							:placeholder="techInfo.weight585 ? techInfo.weight585.toString() : fieldsGroup.weight585.placeholder"
							:min="fieldsGroup.weight585.min"
							:max="fieldsGroup.weight585.max"
							:class="{ 'border border-primary': post.acf.articleMetal == 585 }"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.weight585.label }}</div>
					</div>

					<!-- Weight 750 -->
					<div class="col">
						<input
							v-model="post.acf.weight750"
							:type="fieldsGroup.weight750.type"
							:placeholder="techInfo.weight750 ? techInfo.weight750.toString() : fieldsGroup.weight750.placeholder"
							:min="fieldsGroup.weight750.min"
							:max="fieldsGroup.weight750.max"
							:class="{ 'border border-primary': post.acf.articleMetal == 750 }"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.weight750.label }}</div>
					</div>

					<!-- Weight 950 -->
					<div class="col">
						<input
							v-model="post.acf.weight950"
							:type="fieldsGroup.weight950.type"
							:placeholder="techInfo.weight950 ? techInfo.weight950.toString() : fieldsGroup.weight950.placeholder"
							:min="fieldsGroup.weight950.min"
							:max="fieldsGroup.weight950.max"
							:class="{ 'border border-primary': post.acf.articleMetal == 950 }"
							class="form-control form-select-sm"
						/>
						<div class="form-text">{{ fieldsGroup.weight950.label }}</div>
					</div>
				</div>

				<!-- Diamonds -->
				<hr />
				<p class="text-center fw-bold d-flex align-items-center justify-content-center">
					Бриллианты
					<i @click="addDiamond" class="fas fa-plus-circle fs-5 text-primary clickable-icon ms-2" />
				</p>

				<div v-if="post.acf.diamondStones.length">
					<div class="row small text-secondary">
						<div class="col">Огранка и размер</div>
						<div class="col-3">
							{{ fieldsGroup.diamondStones.sub_fields[1].label }}
						</div>
						<div class="col-2 me-4" v-if="post.acf.isCalc">
							{{ fieldsGroup.diamondStones.sub_fields[2].label }}
						</div>
					</div>

					<draggable v-model="post.acf.diamondStones" item-key="index" group="diamondStones" handle=".draggable" ghost-class="ghost">
						<template #item="{ element, index }">
							<div class="row g-2 mb-2">
								<!-- Название -->
								<div class="col">
									<div class="input-group input-group-sm">
										<label class="input-group-text draggable">{{ ('00' + (index + 1)).substring(('' + (index + 1)).length) }}</label>
										<select v-model="element.diamondTitle" class="form-select">
											<option v-for="(value, key) in fieldsGroup.diamondStones.sub_fields[0].choices" :value="key" :key="key">
												{{ value }}
											</option>
										</select>
									</div>
								</div>

								<!-- Количество -->
								<div class="col-3">
									<input
										v-model="element.pcs"
										:type="fieldsGroup.diamondStones.sub_fields[1].type"
										:min="fieldsGroup.diamondStones.sub_fields[1].min"
										:max="fieldsGroup.diamondStones.sub_fields[1].max"
										class="form-control form-select-sm"
									/>
								</div>

								<!-- Сложность -->
								<div v-if="post.acf.isCalc" class="col-2">
									<select v-model="element.compl" class="form-select form-select-sm">
										<option v-for="(value, key) in fieldsGroup.diamondStones.sub_fields[2].choices" :value="key" :key="key">
											{{ value }}
										</option>
									</select>
								</div>

								<!-- Добавить / удалить -->
								<div class="col-auto p-0 d-flex align-items-center">
									<i @click="addDiamond(index)" class="fas fa-plus small text-primary clickable-icon" />
									<i @click="removeDiamond(index)" class="fas fa-times small text-danger clickable-icon ms-1" />
								</div>
							</div>
						</template>
					</draggable>
				</div>

				<!-- Другие камни -->
				<hr />
				<p class="text-center fw-bold d-flex align-items-center justify-content-center">
					Другие камни <i @click="addOtherStone" class="fas fa-plus-circle fs-5 text-primary clickable-icon ms-2" />
				</p>

				<draggable
					v-if="post.acf.otherStones.length"
					v-model="post.acf.otherStones"
					item-key="index"
					group="otherStones"
					handle=".draggable"
					ghost-class="ghost"
				>
					<template #item="{ element, index }">
						<div class="row align-items-center my-1 g-1">
							<div class="col">
								<div class="row g-2 mb-2">
									<!-- Stone & cut -->
									<div class="col">
										<div class="form-text">Камень и огранка</div>
										<div class="input-group input-group-sm">
											<span class="input-group-text draggable">{{ ('00' + (index + 1)).substring(('' + (index + 1)).length) }}</span>
											<input v-model="element.name" type="text" class="form-control" />
											<input v-model="element.cut" type="text" class="form-control" />
										</div>
									</div>

									<!-- Stone price & calc -->
									<div v-if="post.acf.isCalc" class="col-4">
										<div class="form-text">Цена и учёт</div>
										<div class="input-group input-group-sm">
											<input
												v-model="element.price"
												:type="fieldsGroup.otherStones.sub_fields[4].type"
												:min="fieldsGroup.otherStones.sub_fields[4].min"
												:max="fieldsGroup.otherStones.sub_fields[4].max"
												class="form-control"
											/>
											<div class="input-group-text">
												<input v-model="element.calc" class="form-check-input m-0" type="checkbox" />
											</div>
										</div>
									</div>
								</div>

								<div class="row g-2">
									<!-- Stone size -->
									<div class="col-4">
										<div class="input-group input-group-sm">
											<input v-model="element.size" type="text" class="form-control" />
										</div>
										<div class="form-text">Размер</div>
									</div>

									<!-- Stone weight -->
									<div class="col">
										<div class="input-group input-group-sm">
											<input
												v-model="element.weight"
												:type="fieldsGroup.otherStones.sub_fields[3].type"
												:min="fieldsGroup.otherStones.sub_fields[3].min"
												:max="fieldsGroup.otherStones.sub_fields[3].max"
												class="form-control"
											/>
										</div>
										<div class="form-text">Вес</div>
									</div>

									<!-- Stone pcs -->
									<div class="col-2">
										<div class="input-group input-group-sm">
											<input
												v-model="element.pcs"
												:type="fieldsGroup.otherStones.sub_fields[5].type"
												:min="fieldsGroup.otherStones.sub_fields[5].min"
												:max="fieldsGroup.otherStones.sub_fields[5].max"
												class="form-control"
											/>
										</div>
										<div class="form-text">Штук</div>
									</div>

									<!-- Stone compl -->
									<div v-if="post.acf.isCalc" class="col-2">
										<select v-model="element.compl" class="form-select form-select-sm">
											<option v-for="(value, key) in fieldsGroup.otherStones.sub_fields[6].choices" :value="key" :key="key">
												{{ value }}
											</option>
										</select>
										<div class="form-text">Слож</div>
									</div>
								</div>
							</div>
							<div class="col-auto p-0">
								<i @click="addOtherStone(index)" class="fas fa-plus small text-primary clickable-icon" />
								<i @click="removeOtherStone(index)" class="fas fa-times small text-danger clickable-icon ms-1" />
							</div>
						</div>
					</template>
				</draggable>
			</div>
			<!-- Calc info -->
			<div class="tab-pane fade" id="pills-calc-info">
				<!-- Works -->
				<div class="row g-2">
					<!-- Sketchinh -->
					<div class="col">
						<label class="form-label">{{ fieldsGroup.sketchDays.label }}</label>
						<div class="input-group">
							<input v-model="post.acf.sketchDays" :type="fieldsGroup.sketchDays.type" :min="fieldsGroup.sketchDays.min" class="form-control" />
							<span class="input-group-text" id="basic-addon2">{{ fieldsGroup.sketchDays.append }}</span>
						</div>
					</div>

					<!-- Modeling -->
					<div class="col">
						<label class="form-label">{{ fieldsGroup.modelingDays.label }}</label>
						<div class="input-group">
							<input v-model="post.acf.modelingDays" :type="fieldsGroup.modelingDays.type" :min="fieldsGroup.modelingDays.min" class="form-control" />
							<span class="input-group-text" id="basic-addon2">{{ fieldsGroup.modelingDays.append }}</span>
						</div>
					</div>

					<!-- Mounting -->
					<div class="col">
						<label class="form-label">{{ fieldsGroup.mountingDays.label }}</label>
						<div class="input-group">
							<input v-model="post.acf.mountingDays" :type="fieldsGroup.mountingDays.type" :min="fieldsGroup.mountingDays.min" class="form-control" />
							<span class="input-group-text" id="basic-addon2">{{ fieldsGroup.mountingDays.append }}</span>
						</div>
					</div>
				</div>

				<!-- Engraving & package -->
				<hr />

				<div class="row g-2">
					<!-- Engraving -->
					<div class="col">
						<label class="form-label">{{ fieldsGroup.laserEngraving.label }}</label>
						<div class="input-group">
							<input
								v-model="post.acf.laserEngraving"
								:type="fieldsGroup.laserEngraving.type"
								:min="fieldsGroup.laserEngraving.min"
								class="form-control"
							/>
							<span class="input-group-text" id="basic-addon2">{{ fieldsGroup.laserEngraving.append }}</span>
						</div>
					</div>

					<!-- Package -->
					<div class="col">
						<label class="form-label">{{ fieldsGroup.package.label }}</label>
						<div class="input-group">
							<input v-model="post.acf.package" :type="fieldsGroup.package.type" :min="fieldsGroup.package.min" class="form-control" />
							<span class="input-group-text" id="basic-addon2">{{ fieldsGroup.package.append }}</span>
						</div>
					</div>
				</div>

				<!-- Additional expenses -->
				<hr />
				<p class="text-center fw-bold d-flex align-items-center justify-content-center">
					Дополнительные расходы <i @click="addAdditionalExpense" class="fas fa-plus-circle fs-5 text-primary clickable-icon ms-2" />
				</p>
				<div v-if="post.acf.additionalExpenses.length">
					<div class="row small text-secondary me-4">
						<div class="col">
							{{ fieldsGroup.additionalExpenses.sub_fields[0].label }}
						</div>
						<div class="col-2">
							{{ fieldsGroup.additionalExpenses.sub_fields[1].label }}
						</div>
						<div class="col-3">
							{{ fieldsGroup.additionalExpenses.sub_fields[2].label }}
						</div>
					</div>

					<draggable v-model="post.acf.additionalExpenses" item-key="index" group="additionalExpenses" handle=".draggable" ghost-class="ghost">
						<template #item="{ element, index }">
							<div class="row g-2">
								<!-- Title -->
								<div class="col p-1">
									<div class="input-group input-group-sm">
										<span class="input-group-text draggable">{{ ('00' + (index + 1)).substring(('' + (index + 1)).length) }}</span>
										<input v-model="element.title" type="text" class="form-control" />
									</div>
								</div>

								<!-- Days -->
								<div class="col-2 p-1">
									<input
										v-model="element.days"
										:type="fieldsGroup.additionalExpenses.sub_fields[1].type"
										:min="fieldsGroup.additionalExpenses.sub_fields[1].min"
										:max="fieldsGroup.additionalExpenses.sub_fields[1].max"
										class="form-control form-select-sm"
									/>
								</div>

								<!-- Price -->
								<div class="col-3 p-1">
									<input
										v-model="element.price"
										:type="fieldsGroup.additionalExpenses.sub_fields[2].type"
										:min="fieldsGroup.additionalExpenses.sub_fields[2].min"
										:max="fieldsGroup.additionalExpenses.sub_fields[2].max"
										class="form-control form-select-sm"
									/>
								</div>

								<!-- Add & delete -->
								<div class="col-auto p-0 d-flex align-items-center">
									<i @click="addAdditionalExpense(index)" class="fas fa-plus small text-primary clickable-icon" />
									<i @click="removeAdditionalExpense(index)" class="fas fa-times small text-danger clickable-icon ms-1" />
								</div>
							</div>
						</template>
					</draggable>
				</div>

				<!-- Summary -->
				<hr />
				<div class="row">
					<!-- Extra % -->
					<div class="col-5">
						<label class="form-label">{{ fieldsGroup.extraPercent.label }}</label>
						<div class="input-group">
							<input
								v-model="post.acf.extraPercent"
								:type="fieldsGroup.extraPercent.type"
								:min="fieldsGroup.extraPercent.min"
								:max="fieldsGroup.extraPercent.max"
								class="form-control"
							/>
							<div class="input-group-text">
								{{ fieldsGroup.extraPercent.append }}
							</div>
						</div>
					</div>

					<!-- Final price -->
					<div class="col">
						<label class="form-label">Стоимость, смета верна</label>
						<div class="input-group">
							<input
								v-model="post.acf.price"
								:type="fieldsGroup.price.type"
								:min="fieldsGroup.price.min"
								:max="fieldsGroup.price.max"
								class="form-control"
							/>
							<div class="input-group-text">
								<input v-model="post.acf.calcIsTrue" class="form-check-input m-0" type="checkbox" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SingleJewelry, Media, Server, App } from '@/store'
import _ from 'lodash'
import draggable from 'vuedraggable'
import ImagesLibrary from '@/components/app/images-library.component.vue'
import EditableImage from '@/components/single-jewelry/editable-image.component.vue'

export default {
	name: 'PostEdit',
	components: {
		draggable,
		ImagesLibrary,
		EditableImage,
	},

	data: () => ({
		// mounted: false,
		watch: false,
		post: [],
		activeImageType: '',
	}),

	created() {
		// this.mounted = false
		this.post = SingleJewelry.jewelryItem
	},

	mounted() {
		SingleJewelry.getTaxonomies()
		// this.mounted = true
		this.watch = false

		const editTabEl = document.getElementById(App.editTab)
		const editTab = new this.$bootstrap.Tab(editTabEl)
		editTab.show()
	},

	computed: {
		fieldsGroup() {
			return SingleJewelry.fieldsGroup
		},
		categories() {
			return SingleJewelry.categoriesList
		},
		tags() {
			return SingleJewelry.tagsList
		},
		lastArtickleNumber() {
			const article = SingleJewelry.lastArticleNumber
			return ('0000' + article).substring(article.length)
		},
		techInfo() {
			return SingleJewelry.techInfo
		},
		postChanged() {
			return SingleJewelry.jewelryItemChanged
		},
		images() {
			// return [].concat(...Object.values(SingleJewelry.images))
			return SingleJewelry.images
		},
		allImages() {
			return Media.images
		},
		serverBusy() {
			return Server.loading
		},
	},
	watch: {
		post: {
			immediate: true,
			deep: true,
			handler: _.debounce(function(value) {
				if (this.watch) SingleJewelry.updateJewelryItem({ jewelryItem: value, editStatus: true })
				this.watch = true
			}, 300),
		},
	},
	methods: {
		changeTab(event) {
			App.updateJewelryItemEditTab(event.target.id)
			//console.log(event.target.id)
		},

		addDiamond(index = 0) {
			const newDiamond = {
				diamondTitle: this.fieldsGroup.diamondStones.sub_fields[0].default_value,
				pcs: this.fieldsGroup.diamondStones.sub_fields[1].default_value,
				compl: this.fieldsGroup.diamondStones.sub_fields[2].default_value,
			}
			if (!this.post.acf.diamondStones) this.post.acf.diamondStones = []
			this.post.acf.diamondStones.splice(index + 1, 0, newDiamond)
			console.log('Diamond added after number: ' + (index + 1))
		},
		removeDiamond(index) {
			this.post.acf.diamondStones.splice(index, 1)
			console.log('Diamond at number ' + (index + 1) + ' is removed')
		},
		addOtherStone(index = 0) {
			const newOtherStone = {
				name: this.fieldsGroup.otherStones.sub_fields[0].default_value,
				cut: this.fieldsGroup.otherStones.sub_fields[1].default_value,
				size: this.fieldsGroup.otherStones.sub_fields[2].default_value,
				weight: this.fieldsGroup.otherStones.sub_fields[3].default_value,
				price: this.fieldsGroup.otherStones.sub_fields[4].default_value,
				pcs: this.fieldsGroup.otherStones.sub_fields[5].default_value,
				compl: this.fieldsGroup.otherStones.sub_fields[6].default_value,
				calc: this.fieldsGroup.otherStones.sub_fields[7].default_value ? true : false,
			}
			if (!this.post.acf.otherStones) this.post.acf.otherStones = []
			this.post.acf.otherStones.splice(index + 1, 0, newOtherStone)
			console.log('Other stone added after number: ' + (index + 1))
		},
		removeOtherStone(index) {
			this.post.acf.otherStones.splice(index, 1)
			console.log('Other stone at number ' + (index + 1) + ' is removed')
		},
		addAdditionalExpense(index = 0) {
			const newAdditionalExpense = {
				title: this.fieldsGroup.additionalExpenses.sub_fields[0].default_value,
				days: this.fieldsGroup.additionalExpenses.sub_fields[1].default_value,
				price: this.fieldsGroup.additionalExpenses.sub_fields[2].default_value,
			}
			if (!this.post.acf.additionalExpenses) this.post.acf.additionalExpenses = []
			this.post.acf.additionalExpenses.splice(index + 1, 0, newAdditionalExpense)
			console.log('Additional expense added after number: ' + (index + 1))
		},
		removeAdditionalExpense(index) {
			this.post.acf.additionalExpenses.splice(index, 1)
			console.log('Additional expense at number ' + (index + 1) + ' is removed')
		},
		savePost() {
			if (!Server.loading) {
				console.log('[POST] Save button')
				SingleJewelry.saveJewelryItem()
			}
		},
		getUrlById(id) {
			if (this.images.find((image) => image.id === id)) return this.images.find((image) => image.id === id).thumbnail
		},
		showImagesLibraryModal(imageType) {
			this.activeImageType = imageType
		},
		addImage(id) {
			const imageType = this.activeImageType
			if (!this.post.acf[imageType]) this.post.acf[imageType] = []
			if (!this.post.acf[imageType].find((value) => value === id)) this.post.acf[imageType].push(id)
		},
		deleteImage(event) {
			const imageType = event.imageType
			const id = event.id
			this.post.acf[imageType] = this.post.acf[imageType].filter((value) => value != id)
		},
	},
}
</script>

<style>
input::placeholder {
	color: lightgray;
}
.draggable {
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}
.draggable:active {
	cursor: grab;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}
.sortable-chosen {
	transform: scale(1.1) !important;
}
/* .ghost {
	background: linear-gradient(0.25turn, var(--primary), 30%, white);
} */
textarea {
	resize: none;
}
</style>
