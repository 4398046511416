<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn-outline-primary"
			data-bs-toggle="modal"
			data-bs-target="#f360-modal"
		>
			Модель Fusion 360
		</button>

		<div
			class="modal fade"
			id="f360-modal"
			tabindex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title text-truncate"><b>Fusion 360</b> / {{ title }}</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<iframe
							id="f360-iframe"
							:data-src="embeddedF360"
							width="100%"
							height="100%"
							allowfullscreen="true"
							webkitallowfullscreen="true"
							mozallowfullscreen="true"
							frameborder="0"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'EmbeddedF360Button',
	props: ['embeddedF360', 'title'],
	mounted() {
		const f360Modal = document.getElementById('f360-modal')
		const f360Iframe = document.getElementById('f360-iframe')
		f360Modal.addEventListener('show.bs.modal', () => {
			if (!f360Iframe.src) f360Iframe.src = f360Iframe.dataset.src
		})
	},
})
</script>

<style scoped>
:deep(.modal-content) {
	min-height: 100%;
	height: 100%;
}
:deep(.modal-body) {
	overflow: hidden !important;
}
</style>
