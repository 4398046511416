<template>
	<div class="row w-100 justify-content-center px-2 m-0 single-jewelry-page">
		<!-- Post info wrapper -->
		<div class="col-12 col-lg-6 col-xxl-8 disable-scrollbars post-info-wrapper">
			<div class="row h-100 justify-content-center">
				<!-- Basic info -->
				<div class="col-12 col-xxl-6 p-3 disable-scrollbars post-info-element">
					<SingleJewelryBasicInfo :jewelryItem="jewelryItem" :article="article" :images="images" :key="jewelryItem.id + '-basic-info'" />
				</div>
				<!-- Calc & tech info -->
				<div v-if="calcInfo || techInfo" class="col-12 col-xxl-6 p-3 disable-scrollbars post-info-element">
					<SingleJewelryCalcInfo v-if="calcInfo" :calcInfo="calcInfo" :key="jewelryItem.id + '-calc-info'" />
					<SingleJewelryTechInfo v-if="techInfo" :techInfo="techInfo" :key="jewelryItem.id + '-tech-info'" />
				</div>
			</div>
		</div>

		<!-- Post edit -->
		<div v-if="editPost" class="col-12 col-lg-6 col-xxl-4 p-3 disable-scrollbars post-edit-element">
			<SingleJewelryEdit :key="jewelryItem.id + '-jewelry-edit'" />
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { App, User } from '@/store'
import { SingleJewelry } from '@/store'

import SingleJewelryBasicInfo from '@/components/single-jewelry/single-jewelry-basic-info.component.vue'
import SingleJewelryCalcInfo from '@/components/single-jewelry/single-jewelry-calc-info.component.vue'
import SingleJewelryTechInfo from '@/components/single-jewelry/single-jewelry-tech-info.component.vue'
import SingleJewelryEdit from '@/components/single-jewelry/single-jewelry-edit.component.vue'

export default defineComponent({
	name: 'SingleJewelry',
	components: {
		SingleJewelryBasicInfo,
		SingleJewelryCalcInfo,
		SingleJewelryTechInfo,
		SingleJewelryEdit,
	},
	async beforeRouteEnter(to, from, next) {
		document.title += ' ' + to.params.id.toString().toUpperCase()
		await SingleJewelry.loadJewelryItem(to.params.id)
		console.log('[Single jewelry] Enter page')
		next()
	},

	async beforeRouteUpdate(to, from, next) {
		document.title += ' ' + to.params.id.toString().toUpperCase()
		await SingleJewelry.loadJewelryItem(to.params.id)
		console.log('[Single jewelry] Update page')
		next()
	},

	beforeRouteLeave(to, from, next) {
		if (SingleJewelry.jewelryItemChanged) {
			const result = confirm(`Имеются несохранённые изменения. Перейти без сохранения?`)
			if (result) {
				next()
			}
		} else next()
	},

	computed: {
		jewelryItem() {
			return SingleJewelry.jewelryItem
		},
		article() {
			return SingleJewelry.article
		},
		images() {
			return SingleJewelry.images
		},
		calcInfo() {
			return Object.keys(SingleJewelry.calcInfo).length !== 0 ? SingleJewelry.calcInfo : false
		},
		techInfo() {
			return Object.keys(SingleJewelry.techInfo).length !== 0 ? SingleJewelry.techInfo : false
		},
		editPost() {
			return App.jewelryItemEdit && User.admin
		},
	},
})
</script>
<style scoped>
.single-jewelry-page {
	height: var(--page-height);
}
</style>
