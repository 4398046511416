<template>
	<div class="container p-0 bg-white rounded-3 overflow-hidden bg-white shadow border">
		<!-- Images -->
		<div id="images-slider" class="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="false" data-bs-touch="false">
			<div class="carousel-inner" ref="carousel">
				<div v-for="(image, index) in images" :key="index" class="carousel-item" :class="{ active: !index }">
					<div class="square-ratio">
						<img :src="image.full" class="img-fluid" />
					</div>
				</div>
			</div>

			<!-- Controls -->
			<a v-show="carouselShowControls" class="carousel-control-prev" href="#images-slider" role="button" data-bs-slide="prev">
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Previous</span>
			</a>
			<a v-show="carouselShowControls" class="carousel-control-next" href="#images-slider" role="button" data-bs-slide="next">
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Next</span>
			</a>
		</div>

		<div v-if="jewelryItem" class="container p-4 text-center">
			<!-- Title -->
			<h4 class="fw-bold">{{ jewelryItem.title }}</h4>

			<!-- Article -->
			<h6 class="text-uppercase font-monospace text-muted">{{ article }}</h6>

			<!-- Price -->
			<h4 class="fw-bold">{{ numberToRUB(jewelryItem.acf.price) }}</h4>

			<!-- Description -->
			<p style="white-space: pre-wrap">{{ jewelryItem.acf.description }}</p>

			<EmbeddedF360Button v-if="f360ButtonShow" :embeddedF360="jewelryItem.acf.embeddedF360" :title="jewelryItem.title" />
			<!-- Edit switch -->
			<div v-if="canEdit">
				<hr />
				<div class="form-check form-check-inline form-switch">
					<input v-model="jewelryItemEdit" class="form-check-input" type="checkbox" id="edit-post-switch" />
					<label class="form-check-label" for="edit-post-switch">Редактировать</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { App } from '@/store'
import { User } from '@/store'
import { numberToRUB } from '@/scripts/filters'
import EmbeddedF360Button from '@/components/single-jewelry/embedded-f360-button.component.vue'

export default defineComponent({
	name: 'SingleJewelryBasicInfo',
	props: ['jewelryItem', 'article', 'images'],
	components: {
		EmbeddedF360Button,
	},

	methods: {
		numberToRUB(value) {
			return numberToRUB(value)
		},
	},

	computed: {
		carouselShowControls() {
			return this.images.length >= 2 ? true : false
		},
		canEdit() {
			return User.admin
		},
		jewelryItemEdit: {
			get() {
				return App.jewelryItemEdit
			},
			set() {
				App.toggleJewelryItemEdit()
			},
		},
		f360ButtonShow() {
			return this.jewelryItem.acf.embeddedF360 && (User.admin || User.jeweler) ? true : false
		},
	},
})
</script>
<style>
.carousel-control-prev,
.carousel-control-next {
	height: 20%;
	top: 40%;
}
</style>
