<template>
	<div class="container p-4 bg-white rounded-3 overflow-hidden bg-white shadow border">
		<!-- Title -->
		<p class="h5 fw-bold text-center">Техническая информация</p>
		<!-- Metal info -->
		<table class="table table-sm table-borderless calc-table m-0 small bg-light mb-2">
			<thead class="fw-bold">
				<tr>
					<th>Объём</th>
					<th>Вес 925</th>
					<th>Вес 585</th>
					<th>Вес 750</th>
					<th>Вес 950</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>{{ numberToVolume(techInfo.volume) }}</td>
					<td>{{ numberToWeight(techInfo.weight925) }}</td>
					<td>{{ numberToWeight(techInfo.weight585) }}</td>
					<td>{{ numberToWeight(techInfo.weight750) }}</td>
					<td>{{ numberToWeight(techInfo.weight950) }}</td>
				</tr>
			</tbody>
		</table>

		<!-- Stones info -->
		<table class="table table-sm table-borderless table-hover calc-table m-0 small">
			<thead v-if="techInfo.diamondStones || techInfo.otherStones" class="fw-bold">
				<tr>
					<th>Тип</th>
					<th>Огранка</th>
					<th>Размер</th>
					<th>Вес</th>
					<th>Штук</th>
				</tr>
			</thead>

			<tbody v-if="techInfo.diamondStones">
				<tr v-for="(diamondStone, index) in techInfo.diamondStones" :key="index" :diamondStone="diamondStone">
					<td>{{ diamondStone.name }}</td>
					<td>{{ diamondStone.cut }}</td>
					<td>{{ diamondStone.size }}</td>
					<td>{{ diamondStone.weight }}</td>
					<td>{{ diamondStone.pcs }}</td>
				</tr>
			</tbody>

			<tbody v-if="techInfo.otherStones">
				<tr v-for="(otherStone, index) in techInfo.otherStones" :key="index" :otherStone="otherStone">
					<td>{{ otherStone.name }}</td>
					<td>{{ otherStone.cut }}</td>
					<td>{{ otherStone.size }}</td>
					<td>{{ otherStone.weight }}</td>
					<td>{{ otherStone.pcs }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { numberToVolume, numberToWeight } from '@/scripts/filters'

export default defineComponent({
	name: 'SingleJewelryTechInfo',
	props: ['techInfo'],
	methods: {
		getDiamondCut(title) {
			return title.split(' ')[0].toLowerCase()
		},
		getDiamondSize(title) {
			return title.split(' ')[1]
		},
		numberToVolume(value) {
			return numberToVolume(value)
		},
		numberToWeight(value) {
			return numberToWeight(value)
		},
	},
})
</script>

<style scoped>
th {
	padding: 5px 10px;
}
td {
	padding: 0 10px;
}
</style>
