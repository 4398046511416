<template>
	<div class="square-ratio my-1">
		<i
			@click="deleteImage(id, imageType)"
			class="fas fa-times-circle fs-6 text-danger clickable-icon"
			style="position: absolute; top: 3px; left: 3px; z-index: 100;"
		/>
		<img :src="url" class="draggable img-fluid img-thumbnail" />
	</div>
</template>

<script>
export default {
	name: 'EditableImage',
	props: {
		id: Number,
		url: String,
		imageType: String,
	},
	data() {
		return {}
	},
	methods: {
		deleteImage(id, imageType) {
			this.$emit('deleteImage', { id, imageType })
		},
	},
}
</script>
